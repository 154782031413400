import request from './request.js';

//获取自动分单列表
const get_orders_list = (params) => {
  return request({
    url: '/auto-inst/list-auto',
    method: 'GET',
    params
  })
};

//查询所有员工
const find_all = (params) => {
  return request({
    url: '/system-user/login/find-all',
    method: 'GET',
    params
  })
}

//获取已勾选员工
const get_checked_staff = (params) => {
  return request({
    url: '/auto-inst/find-auto',
    method: 'GET',
    params
  })
}

//修改员工分单列表
const change_order_list = (data) => {
  return request({
    url: '/auto-inst/add-or-update',
    method: 'POST',
    data
  })
}

export {
  get_orders_list,
  find_all,
  get_checked_staff,
  change_order_list
}