<template>
  <div style="margin-top: 40px">
    <!-- <div class="top" style="margin: 20px 0">
      <el-button type="primary" @click="openDialog" style="margin-right: 15px"
        >编辑</el-button
      >
    </div> -->
    <el-dialog title="自动分单管理" :visible.sync="dialogTableVisible">
      <div>
        <div>
          <span style="display: inline-block; margin: 0 20px 20px 0"
            >所有员工:</span
          >
          <el-checkbox-group v-model="checkedStaff" @change="selectStaff"
            ><el-checkbox
              :label="item.id"
              border
              v-for="item in allStaff"
              :key="item.id"
              >{{ item.userName }}</el-checkbox
            ></el-checkbox-group
          >
        </div>
        <el-table
          :data="checkedStaffTableData"
          border
          style="width: 100%; margin-top: 30px"
        >
          <el-table-column prop="institutionUserName" label="姓名">
          </el-table-column>
          <el-table-column prop="rate" label="分单比例">
            <template slot-scope="scope">
              <el-input
                v-model.number="scope.row.rate"
                style="width: 80px"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="certain" @click="certain" v-preventClick>确认</div>
    </el-dialog>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :span-method="objectSpanMethod"
    >
      <el-table-column prop="institutionName" label="子计划">
        <template slot-scope="scope">
          {{ scope.row.institutionName || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="institutionUserName" label="员工">
        <template slot-scope="scope">
          {{ scope.row.institutionUserName || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="rate" label="比例">
        <template slot-scope="scope">
          {{ scope.row.rate || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="todayNum" label="今日已分单数">
        <template slot-scope="scope">
          {{ scope.row.todayNum || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="totaNum" label="总分单数">
        <template slot-scope="scope">
          {{ scope.row.totaNum || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="编辑">
        <template slot-scope="scope"
          ><el-button
            size="small"
            @click="openDialog(scope.row.institutionId)"
            type="primary"
            >编辑</el-button
          ></template
        >
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="page"
      :limit.sync="pageSize"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import {
  get_orders_list,
  find_all,
  get_checked_staff,
  change_order_list,
} from "../../api/AutoOrderManagement";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      checkedState: true,
      spanArr: [],
      tableData: [],
      dialogTableVisible: false,
      total: 0,
      page: 1,
      pageSize: 10,
      //所有员工
      allStaff: [],
      checkedStaff: [],
      checkedStaffTableData: [],
      flag: false,
      institutionId: ""
    };
  },
  mounted() {
    this.loanList();
    this.getAll();
  },
  methods: {
      //合并单元格调用方法
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0 || columnIndex === 5) {
          /*
          表格数据：this.tableList
          判断合并行数：this.mergeColumn()
          */
          const _row = (this.mergeColumn(this.tableData).one)[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      },
      //判断合并行数
      mergeColumn(data) {
        const spanOneArr = []
        let concatOne = 0
        data.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            //name 修改
            if (item.institutionId === data[index - 1].institutionId) { //第一列需合并相同内容的字段
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return {
          one: spanOneArr
        }
      },
    //编辑
    openDialog(data) {
      this.dialogTableVisible = true;
      this.institutionId = data
      this.getCheckedStaff(data);
    },
    loanList() {
      get_orders_list({
        pageIdx: this.page,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code === 200) {
              this.tableData = []
          let tableData = []
          tableData = res.data.records;
          tableData.forEach((item) => {
            if(item.sonList.length) {
              item.sonList.forEach(doItem => {
                doItem.institutionId = item.institutionId
                doItem.institutionName = item.institutionName
              this.tableData.push(doItem)
              })
            } else {
              let sunItem = {}
              sunItem.institutionId = item.institutionId
              sunItem.institutionName = item.institutionName
              this.tableData.push(sunItem)
            }
          })
          this.total = res.data.total;
        }
      });
    },
    certain(e) {
      if (this.flag) {
        return false;
      } else {
        this.flag = true;
        this.timer = setTimeout(() => {
          this.flag = false;
          clearTimeout(this.timer);
          this.timer = null;
        }, 1500);
      }
      if (!this.checkedStaffTableData.length) {
        let obj = {
          institutionUserName: "",
          institutionUserId: "",
          rate: "",
          institutionId: this.institutionId
        };
        change_order_list([obj]).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.dialogTableVisible = false;
            this.loanList();
          }
        });
        return false;
      }
      let sub = 0;
      this.checkedStaffTableData.forEach((item) => {
        sub += item.rate;
      });
      if (sub !== 100) {
        this.$message({
          message: "分单比例综合必须为100",
          type: "error",
        });
      } else {
        change_order_list(this.checkedStaffTableData).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.dialogTableVisible = false;
            this.loanList();
          }
        });
      }
    },
    //获取所有员工
    getAll() {
      find_all({
        status: 1,
      }).then((res) => {
        if (res.code === 200) {
          this.allStaff = res.data;
        }
      });
    },
    //获取已经被选中的员工
    getCheckedStaff(data) {
      get_checked_staff({
        institutionId: data
      }).then((res) => {
        if (res.code === 200) {
          if(!res.data.length) {
            this.checkedState = true
          } else {
            this.checkedState = false
          }
          this.checkedStaffTableData = []
          res.data.forEach(item => {
            if(item.institutionUserName) {
              this.checkedStaffTableData.push(item)
            }
          })
          // this.checkedStaffTableData = res.data;
          this.checkedStaffTableData.forEach((row) => {
            this.checkedStaff.push(row.institutionUserId);
          });
        }
      });
    },
    selectStaff(value) {
      console.log(value,222);
      console.log(this.checkedStaff,);
      console.log(this.allStaff,333);
      let newCheckedStaff = [];
      if (value.length > this.checkedStaffTableData.length) {
        let onlyStaff = {};
        this.allStaff.forEach((item) => {
          if (item.id === value[value.length - 1]) {
            onlyStaff = item;
          }
        });
        // console.log(onlyStaff,111);
        const obj = {
          institutionUserName: onlyStaff.userName,
          institutionUserId: onlyStaff.id,
          rate: "",
          institutionId: this.institutionId
        };
        newCheckedStaff = [obj, ...this.checkedStaffTableData];
      } else {
        value.forEach((item) => {
          this.checkedStaffTableData.forEach((row) => {
            if (item === row.institutionUserId) {
              newCheckedStaff.push(row);
            }
          });
        });
      }
      this.checkedStaffTableData = newCheckedStaff;
      if(this.checkedState && this.checkedStaffTableData.length) {
        let rate = Math.floor(100/this.checkedStaffTableData.length)
        let firstRate = 100 - rate * (this.checkedStaffTableData.length - 1)
        this.checkedStaffTableData.forEach((item,index) => {
          item.rate = rate

        })
        this.checkedStaffTableData[0].rate = firstRate
      }
      console.log(this.checkedStaffTableData);
    },
    //分页
    pagination(params) {
      if (params.page) {
        this.page = params.page;
      } else {
        this.page = 1;
        this.pageSize = params.limit;
      }
      this.loanList();
    },
  },
  watch: {
    dialogTableVisible(newVal) {
      if (!newVal) {
        this.checkedStaff = [];
        this.institutionId = ""
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-date-editor .el-range-separator {
  width: 9%;
}
::v-deep .el-date-editor--daterange.el-input__inner {
  width: 250px !important;
}
::v-deep .el-select {
  width: 150px;
  margin-right: 10px;
}
::v-deep .el-radio {
  margin-right: 10px;
}

::v-deep thead th {
  font-weight: 400;
}

::v-deep .cell {
  text-align: center;
}

::v-deep .el-checkbox {
  margin: 0 10px 10px 0 !important;
}

.certain {
  width: 300px;
  height: 50px;
  margin: 30px auto 0;
  border-radius: 25px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background: rgb(64, 158, 255);
  cursor: pointer;
}

::v-deep .cell {
  text-align: center;
}

::v-deep .el-pagination {
  text-align: center;
}
</style>